import {auth} from "../firebase";

export function checkUsersRoles() {
	return new Promise((resolve) => {
		auth.onAuthStateChanged(async (user) => {
			let roles = {
				isAdmin: false,
				isEditor: false,
				isAssembler: false,
				isWriter: false,
				isSpecialist: false,
				hasApprovalAccess: false,
				hasRatingAccess: false,
				isReadOnlyAccess: false,
				isViewAllPlans: false,
				hasAdAccess:false
				
			};

			if (user) {
				const idTokenResult = await user.getIdTokenResult(true);

				if (idTokenResult.claims) {
					roles = {
						isAdmin: !!idTokenResult.claims.admin,
						isAssembler: !!idTokenResult.claims.assembler,
						isEditor: !!idTokenResult.claims.editor,
						isWriter: !!idTokenResult.claims.writer,
						isSpecialist: !!idTokenResult.claims.specialist,
						isReadOnlyAccess: !!idTokenResult.claims.readOnlyAccess,
						isViewAllPlans: !!idTokenResult.claims.viewAllPlans,
						hasApprovalAccess: !!idTokenResult.claims.approvalAccess,
						hasRatingAccess: !!idTokenResult.claims.ratingAccess,
						hasAdAccess: !!idTokenResult.claims.adAccess,
                        isFeedbackSupervisor: !!idTokenResult.claims.feedbackSupervisor
					};
				}
			}
			resolve(roles);
		});
	});
}

export async function extractDomain(url) {
    var domain;
    try {
        //find & remove protocol (http, ftp, etc.) and get domain
        if (url.indexOf("://") > -1) {
            domain = url.split("/")[2];
        } else {
            domain = url.split("/")[0];
        }

        //find & remove www
        if (domain.indexOf("www.") > -1) {
            domain = domain.split("www.")[1];
        }

        domain = domain.split(":")[0]; //find & remove port number
        domain = domain.split("?")[0]; //find & remove url params
    } catch (e) {
        console.error(e);
    }
    return domain;
}

export function addDaysToDate(dateString, daysToAdd) {
    try {
        try {
            dateString = dateString.toDate();
        } catch (e) {
            console.log(e);
        }
        // Parse the date string and create a Date object
        let date = new Date(dateString);
        if (date > new Date()) {
            date = new Date();
        }
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date format");
        }

        // Add the days to the date
        date.setDate(date.getDate() + daysToAdd);

        // Return the new date
        return date;
    } catch (e) {
        console.log(e);
    }
}
export function formatDate(dateString) {
    try {
        const date = new Date(dateString);
        // Then specify how you want your dates to be formatted
        return new Intl.DateTimeFormat("default", {dateStyle: "short"}).format(date);
    } catch (e) {
        return "";
    }
}