import {createApp} from "vue";
import {VueFire, VueFireAuth} from "vuefire";

import {firebaseApp} from "./firebase";
import App from "./App.vue";

import ResizeTextarea from "resize-textarea-vue3";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import {BootstrapIconsPlugin} from "bootstrap-icons-vue";

import PrimeVue from "primevue/config";

import router from "./router";
import store from "./store";
import "./assets/css/main.css";
import * as Sentry from "@sentry/vue";

const app = createApp(App);
app.config.errorHandler = (err) => {
	alert(err);
};
app.use(VueFire, {
	// imported above but could also just be created here
	firebaseApp,
	modules: [
		// we will see other modules later on
		VueFireAuth(),
	],
});

app.use(ResizeTextarea);
app.use(PrimeVue);
app.use(BootstrapIconsPlugin);

Sentry.init({
	app,
	dsn: "https://51f49c974ba646cf03a1789973e4f7a6@o4505987591110656.ingest.sentry.io/4505987593666560",
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", /^https:\/\/.*run\.app/],
			trackComponents: true,
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			beforeNavigate: (context) => {
				return {
					...context,
					// You could use your UI's routing library to find the matching
					// route template here. We don't have one right now, so do some basic
					// parameter replacements.
					name: location.pathname.replace(/\/cdaps\/[a-zA-Z0-9]{9}/g, "/cdaps/<cdapId>"),
				};
			},
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.use(store);

app.mount("#app");
