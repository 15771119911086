import {createWebHistory, createRouter} from "vue-router";
import {analytics, logEvent, auth} from "./firebase";
import {getCurrentUser} from "vuefire";
import {nextTick} from "vue";
const DEFAULT_TITLE = "Plan Dashboard";

const routes = [
	{
		path: "/cdaps/:cdapId*",
		name: "cdaps",
		component: () => import("./views/CDAPsList"),
		props: true,
		meta: {title: "Plan > Edit", requiresAuth: true},
	},
	{
		path: "/feedback-list",
		name: "feedback-list",
		component: () => import("./views/FeedbackList.vue"),
		props: true,
		meta: {title: "Plan > Feedback", requiresAuth: true},
	},
	{
		path: "/feedback/:uniqueId*",
		name: "feedback-form",
		component: () => import("./views/ChangeFeedbackFrom.vue"),
		props: true,
		meta: {title: "Plan > Feedback", requiresAuth: true},
	},
	{
		path: "/cdaps-multi",
		name: "cdaps-multi",
		component: () => import("./views/MultiCDAPList"),
		meta: {title: "Plan > View > Multi", requiresAuth: true},
	},
	{
		path: "/",
		redirect: "/cdaps",
	},
	{
		path: "/cdap-details/:cdapId",
		name: "CdapDetails",
		component: () => import("./views/CDAP"),
		props: true,
		meta: {requiresAuth: true},
	},

	{
		path: "/add",
		name: "add",
		component: () => import("./views/AddPlan"),
		meta: {title: "Plan > Add", requiresAuth: true},
		beforeEnter: (to, from, next) => {
			const unsubscribe = auth.onAuthStateChanged(async (user) => {
				if (user) {
					const idTokenResult = await user.getIdTokenResult(true);

					if ((idTokenResult.claims && idTokenResult.claims.specialist) || idTokenResult.claims.writer) {
						next();
					} else {
						next("/add-assisted");
					}
				} else {
					next("/login");
				}
				unsubscribe();
			});
		},
	},
	{
		path: "/login",
		name: "login",
		component: () => import("./views/Login"),
	},
	{
		path: "/manage-users",
		name: "users",
		component: () => import("./views/ManageUsers"),
		meta: {requiresAuth: true},
		beforeEnter: (to, from, next) => {
			const unsubscribe = auth.onAuthStateChanged(async (user) => {
				if (user) {
					const idTokenResult = await user.getIdTokenResult(true);

					if (idTokenResult.claims && idTokenResult.claims.admin) {
						next();
					} else {
						next("/");
					}
				} else {
					next("/login");
				}
				unsubscribe();
			});
		},
	},
	{
		path: "/manage-settings",
		name: "settings",
		component: () => import("./views/ManageSettings"),
		meta: {requiresAuth: true},
		beforeEnter: (to, from, next) => {
			const unsubscribe = auth.onAuthStateChanged(async (user) => {
				if (user) {
					const idTokenResult = await user.getIdTokenResult(true);

					if (idTokenResult.claims && idTokenResult.claims.admin) {
						next();
					} else {
						next("/");
					}
				} else {
					next("/login");
				}
				unsubscribe();
			});
		},
	},
	{
		path: "/manage-prompts",
		name: "prompts",
		component: () => import("./views/PromptManager"),
		meta: {requiresAuth: true},
		beforeEnter: (to, from, next) => {
			const unsubscribe = auth.onAuthStateChanged(async (user) => {
				if (user) {
					const idTokenResult = await user.getIdTokenResult(true);

					if (idTokenResult.claims && idTokenResult.claims.admin) {
						next();
					} else {
						next("/");
					}
				} else {
					next("/login");
				}
				unsubscribe();
			});
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// if the returned position is falsy or an empty object,
		// will retain current scroll position.
		let position = {};

		// if link has anchor, scroll to anchor
		if (to.hash) {
			position = {el: to.hash};
		}

		// if savedPosition is available, use it
		if (savedPosition) {
			position = savedPosition;
		}

		return new Promise((resolve) => {
			// wait for the out transition to complete (if necessary)
			router.isReady().then(() => {
				// if the resolved position is falsy or an empty object,
				// will retain current scroll position.
				resolve(position);
			});
		});
	},
});

router.beforeEach(async (to) => {
	// routes with `meta: { requiresAuth: true }` will check for the users, others won't
	if (to.meta.requiresAuth) {
		const currentUser = await getCurrentUser();
		// if the user is not logged in, redirect to the login page
		if (!currentUser) {
			return {
				path: "/login",
				query: {
					// we keep the current path in the query so we can redirect to it after login
					// with `router.push(route.query.redirect || '/')`
					redirect: to.fullPath,
				},
			};
		}
	} else if (to.name === "login") {
		const currentUser = await getCurrentUser();
		if (currentUser) {
			return {
				path: "/",
			};
		}
	}
});
router.beforeEach(async () => {
	logEvent(analytics, "page_view", {type: "internal"});
});

router.afterEach((to) => {
	nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE;
	});
});

export default router;
