import {createStore} from "vuex";
import {auth, analytics, logEvent} from "./firebase";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";

const store = createStore({
	state: {
		user: {
			loggedIn: false,
			data: null,
		},
	},

	getters: {
		user(state) {
			return state.user;
		},
	},
	mutations: {
		SET_USER(state, payload) {
			state.user.data = payload;
		},
		SET_LOGGED_IN(state, value) {
			state.user.loggedIn = value;
		},
	},
	actions: {
		async logIn(context) {
			let provider = new GoogleAuthProvider();
			try {
				let result = await signInWithPopup(auth, provider);

				context.commit("SET_USER", result.user);
				logEvent(analytics, "login", {method: "Google"});

				return result.user;
			} catch (err) {
				console.log(err);
				throw new Error("login failed");
			}
		},

		async logOut(context) {
			await auth.signOut().then(() => {
				alert("Successfully signed out.");
			});
			context.commit("SET_USER", null);
		},

		async fetchUser(context, user) {
			context.commit("SET_LOGGED_IN", user !== null);
			if (user) {
				context.commit("SET_USER", user);
			} else {
				context.commit("SET_USER", null);
			}
		},
	},
});

// export the store
export default store;
