<!-- eslint-disable vue/no-multiple-template-root -->
<template>
	<svg xmlns="http://www.w3.org/2000/svg" style="display: none">
		<symbol id="bootstrap" viewBox="0 0 512 408" fill="currentcolor">
			<path
				d="M106.342 0c-29.214 0-50.827 25.58-49.86 53.32.927 26.647-.278 61.165-8.966 89.31C38.802 170.862 24.07 188.707 0 191v26c24.069 2.293 38.802 20.138 47.516 48.37 8.688 28.145 9.893 62.663 8.965 89.311C55.515 382.42 77.128 408 106.342 408h299.353c29.214 0 50.827-25.58 49.861-53.319-.928-26.648.277-61.166 8.964-89.311 8.715-28.232 23.411-46.077 47.48-48.37v-26c-24.069-2.293-38.765-20.138-47.48-48.37-8.687-28.145-9.892-62.663-8.964-89.31C456.522 25.58 434.909 0 405.695 0H106.342zm236.559 251.102c0 38.197-28.501 61.355-75.798 61.355h-87.202a2 2 0 01-2-2v-213a2 2 0 012-2h86.74c39.439 0 65.322 21.354 65.322 54.138 0 23.008-17.409 43.61-39.594 47.219v1.203c30.196 3.309 50.532 24.212 50.532 53.085zm-84.58-128.125h-45.91v64.814h38.669c29.888 0 46.373-12.03 46.373-33.535 0-20.151-14.174-31.279-39.132-31.279zm-45.91 90.53v71.431h47.605c31.12 0 47.605-12.482 47.605-35.941 0-23.46-16.947-35.49-49.608-35.49h-45.602z"
			/>
		</symbol>
		<symbol id="check2" viewBox="0 0 16 16">
			<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
		</symbol>
		<symbol id="circle-half" viewBox="0 0 16 16">
			<path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" />
		</symbol>
		<symbol id="moon-stars-fill" viewBox="0 0 16 16">
			<path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z" />
			<path
				d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"
			/>
		</symbol>
		<symbol id="sun-fill" viewBox="0 0 16 16">
			<path
				d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"
			/>
		</symbol>
		<symbol id="three-dots" viewBox="0 0 16 16">
			<path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
		</symbol>
	</svg>
	<header class="navbar navbar-expand-lg bd-navbar sticky-top">
		<nav class="container-xxl bd-gutter flex-wrap flex-lg-nowrap" aria-label="Main navigation">
			<router-link to="/" class="navbar-brand">
				<span v-if="permissions.isSpecialist">Meeting Assistant</span>
				<span v-else>
					<img width="50" class="mx-2" :src="require('../assets/logo.png')" />
					Plan Assistant
				</span>
			</router-link>
			<div class="d-flex">
				<button class="navbar-toggler d-flex d-lg-none order-3 p-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#bdNavbar" aria-controls="bdNavbar" aria-label="Toggle navigation">
					<svg class="bi" aria-hidden="true"><use xlink:href="#three-dots"></use></svg>
				</button>
			</div>
			<div class="offcanvas-lg offcanvas-end flex-grow-1" tabindex="-1" id="bdNavbar" aria-labelledby="bdNavbarOffcanvasLabel" data-bs-scroll="true">
				<div class="offcanvas-header px-4 pb-0">
					<h5 class="offcanvas-title text-white" id="bdNavbarOffcanvasLabel">Assisted Writer</h5>
					<button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" data-bs-target="#bdNavbar"></button>
				</div>
				<div class="offcanvas-body p-4 pt-0 p-lg-0">
					<hr class="d-lg-none text-white-50" />
					<ul class="navbar-nav flex-row flex-wrap bd-navbar-nav">
						<li class="nav-item col-6 col-lg-auto" v-if="user.loggedIn">
							<router-link to="/cdaps" class="nav-link py-2 px-0 px-lg-2">List</router-link>
						</li>
						<li class="nav-item col-6 col-lg-auto" v-if="user.loggedIn && permissions.isWriter">
							<router-link to="/add" class="nav-link py-2 px-0 px-lg-2">New</router-link>
						</li>
						<li class="nav-item col-6 col-lg-auto" v-if="user.loggedIn && permissions.isSpecialist">
							<router-link to="/add" class="nav-link py-2 px-0 px-lg-2">Add Meeting</router-link>
						</li>
						<li class="nav-item dropdown col-6 col-lg-auto" v-if="user.loggedIn">
							<a class="nav-link dropdown-toggle py-2 px-0 px-lg-2" href="#" id="navbarFeedbackDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Feedback</a>
							<ul class="dropdown-menu" aria-labelledby="navbarFeedbackDropdown">
								<li><router-link to="/feedback" class="dropdown-item">Add New</router-link></li>
								<li><router-link to="/feedback-list" class="dropdown-item">View List</router-link></li>
							</ul>
						</li>
						<!-- New dropdown code -->
						<li class="nav-item dropdown col-6 col-lg-auto" v-if="permissions.isAdmin">
							<a class="nav-link dropdown-toggle py-2 px-0 px-lg-2" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Manage</a>
							<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
								<li><router-link to="/manage-users" class="dropdown-item">Manage Users</router-link></li>
								<li><router-link to="/manage-prompts" class="dropdown-item">Manage Prompts</router-link></li>
							</ul>
						</li>
					</ul>
					<hr class="d-lg-none text-white-50" />

					<ul class="navbar-nav flex-row flex-wrap ms-md-auto">
						<li class="nav-item py-2 py-lg-1 col-12 col-lg-auto" v-if="!user.loggedIn">
							<div class="text-end">
								<button type="button" class="btn btn-warning" @click.prevent="Login">Login</button>
							</div>
						</li>
						<li class="nav-item dropdown">
							<button class="btn btn-link nav-link py-2 px-0 px-lg-2 dropdown-toggle d-flex align-items-center" id="bd-theme" type="button" aria-expanded="false" data-bs-toggle="dropdown" data-bs-display="static" aria-label="Toggle theme (dark)">
								<svg class="bi my-1 theme-icon-active"><use href="#moon-stars-fill"></use></svg>
								<span class="d-lg-none ms-2" id="bd-theme-text">Toggle theme</span>
							</button>
							<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="bd-theme-text">
								<li>
									<button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="light" aria-pressed="false">
										<svg class="bi me-2 opacity-50 theme-icon"><use href="#sun-fill"></use></svg>
										Light
										<svg class="bi ms-auto d-none"><use href="#check2"></use></svg>
									</button>
								</li>
								<li>
									<button type="button" class="dropdown-item d-flex align-items-center active" data-bs-theme-value="dark" aria-pressed="true">
										<svg class="bi me-2 opacity-50 theme-icon"><use href="#moon-stars-fill"></use></svg>
										Dark
										<svg class="bi ms-auto d-none"><use href="#check2"></use></svg>
									</button>
								</li>
								<li>
									<button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="auto" aria-pressed="false">
										<svg class="bi me-2 opacity-50 theme-icon"><use href="#circle-half"></use></svg>
										Auto
										<svg class="bi ms-auto d-none"><use href="#check2"></use></svg>
									</button>
								</li>
							</ul>
						</li>
						<li class="nav-item py-2 py-lg-1 col-12 col-lg-auto">
							<div class="vr d-none d-lg-flex h-100 mx-lg-2 text-white"></div>
							<hr class="d-lg-none my-2 text-white-50" />
						</li>
						<li class="nav-item py-2 py-lg-1 col-12 col-lg-auto" v-if="user.loggedIn">
							<div class="dropdown">
								<a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true">
									<img :src="user.data?.photoURL" alt="mdo" width="32" height="32" class="rounded-circle" referrerpolicy="no-referrer" />
								</a>
								<ul class="dropdown-menu text-small" data-popper-placement="bottom-start">
									<li>
										<a class="dropdown-item" href="#" @click.prevent="signOut">Logout</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
</template>
<script>
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed} from "vue";
import {auth} from "../firebase";
import {checkUsersRoles} from "../scripts/utils.js";

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Navbar",
	data() {
		return {
			permissions: {
				isAdmin: false,
				isEditor: false,
				isAssembler: false,
				isWriter: false,
				isSpecialist: false,
			},
		};
	},
	async created() {
		this.permissions = await checkUsersRoles();
	},
	setup() {
		const store = useStore();
		const router = useRouter();

		auth.onAuthStateChanged((user) => {
			store.dispatch("fetchUser", user);
		});

		const user = computed(() => {
			return store.getters.user;
		});

		const signOut = async () => {
			await store.dispatch("logOut");
			router.push("/");
		};
		const Login = async () => {
			try {
				await store.dispatch("logIn");
				const idTokenResult = await auth.currentUser.getIdTokenResult(true);

				if (idTokenResult.claims.writer) {
					console.log("The user is a writer");
					// Add any additional logic for writers here
				} else {
					console.log("The user is not a writer");
					// Add any additional logic for non-writers here
				}
				router.push("/");
			} catch (err) {
				console.log(err.message);
			}
		};

		return {user, signOut, Login};
	},
	methods: {
		async checkIfUserIsAdmin() {
			return await auth.onAuthStateChanged(async (user) => {
				if (user) {
					const idTokenResult = await user.getIdTokenResult(true);

					if (idTokenResult.claims && idTokenResult.claims.admin) {
						this.permissions.isAdmin = true;
					} else {
						this.permissions.isAdmin = false;
					}
				} else {
					this.permissions.isAdmin = false;
				}
			});
		},

		// Implement your function to check if the user is an admin
		// This can be a call to your authentication or user service
	},
};
</script>

<style scoped>
/* Add your styles here */
</style>
