import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
import {collection, orderBy, query} from "firebase/firestore";
import {getFunctions} from "firebase/functions";
import {getAuth} from "firebase/auth";
import {useFirestore} from "vuefire";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
	apiKey: "AIzaSyA5hpApGSyVm5iAbDz0Tuf2LO3K2e2WX4U",
	authDomain: "gustinquon-1494618178089.firebaseapp.com",
	databaseURL: "https://gustinquon-1494618178089-default-rtdb.firebaseio.com",
	projectId: "gustinquon-1494618178089",
	storageBucket: "gustinquon-1494618178089.appspot.com",
	messagingSenderId: "931580322630",
	appId: "1:931580322630:web:a2fbc011d132f32ab03b47",
	measurementId: "G-VHHS07VW7F",
};

const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);
const firebaseDb = useFirestore();
const analytics = getAnalytics(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);
export const usersCollection = collection(firebaseDb, "/users");

// Your other imports and initializations

export const cdapWriterCollection = collection(firebaseDb, "/cdapwriter");
export const promptManagerCollection = collection(firebaseDb, "prompts");
export const eventTypeCollection = collection(firebaseDb, "calendly-event-types");
export const feedbackCollection = collection(firebaseDb, "/feedback");

// Now you can order by 'created'

export const cdapWriterCollectionQuery = query(cdapWriterCollection, orderBy("status", "asc"), orderBy("created", "desc"));

export {firebaseDb, auth, firebaseApp, analytics, logEvent};
