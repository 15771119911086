<template>
	<div id="app">
		<Navbar />
		<div class="">
			<div class="container-xxl gx-0">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import ColorModeToggler from "./assets/js/themeSwitcher.js";
import Navbar from "./components/Navbar";

export default {
	name: "app",
	// eslint-disable-next-line vue/no-unused-components
	components: {Navbar},
	setup() {},
	mounted() {
		// Initialize ColorModeToggler when the component is mounted
		ColorModeToggler.initialize();
	},
};
</script>

<style scoped>
.container h2 {
	text-align: center;
	margin: 25px auto;
}
body {
	font-family: var(--font-family);
}
</style>
